import { useAccount, useCall, useWriteContract } from "wagmi"
import { stakeContractConfig } from "../../config/contract"
import { useCallback, useMemo } from "react"
import { erc721Abi } from "viem"
import { nftContract, tokenID } from "../../config"
import { showError, showFulfill } from "../../utils/pageHelpers"
import stakeContractAbi from "../../config/abis/stakeABI.json"

export const useStakeCall = () => {
    const {data: hash, isPending, isError, error, writeContract} = useWriteContract({
        mutation: {
            onError: (error) => {
                console.error("Error calling contract:", error)
                showError(error.shortMessage)
            },
            onMutate: () => {
                console.debug("Calling contract...")
            },
            onSuccess: (data) => {
                console.debug("Contract called successfully:", data)
                showFulfill("Transaction broadcasted to polygon!<br/>Check txn: <a style='text-decoration: none;' href='https://polygonscan.com/tx/" + data + "' target='_blank'>" + data.substring(0, 4) + "..." + data.substring(data.length - 4) + "</a>")
            }
        }
    })

    const triggerContract = useCallback( (address, abi, method, args) => {
        return writeContract({
            address: address,
            abi: abi,
            functionName: method,
            args: args
        })
    }, [writeContract])

    return {hash, isPending, isError, error, triggerContract}
}

export const useApproveNFT = () => {
    const {hash, isPending, isError, error, triggerContract} = useStakeCall()

    const approveNFT = useCallback(async () => {
        try {
            triggerContract(nftContract, erc721Abi, "setApprovalForAll", [stakeContractConfig.address, true])
        } catch (error) {
            console.error("Error approving NFT:", error)
        }
    }, [triggerContract])

    return useMemo(() => ({approveNFT, hash, isPending, isError, error}), [approveNFT, hash, isPending, isError, error])
}

export const useStakeNFT = () => {
    const {hash, isPending, isError, error, triggerContract} = useStakeCall()

    const stakeNFT = useCallback(async (tokenId) => {
        try {
            triggerContract(stakeContractConfig.address, stakeContractAbi, "stakeTMTNFT", [tokenID])
        } catch (error) {
            console.error("Error staking NFT:", error)
        }
    }, [triggerContract])

    return useMemo(() => ({stakeNFT, hash, isPending, isError, error}), [stakeNFT, hash, isPending, isError, error])
}

export const useClaimTMT = () => {
    const {hash, isPending, isError, error, triggerContract} = useStakeCall()

    const claimTMT = useCallback(async () => {
        try {
            triggerContract(stakeContractConfig.address, stakeContractAbi, "withdrawTMT", [])
        } catch (error) {
            console.error("Error claiming TMT:", error)
        }
    }, [triggerContract])

    return useMemo(() => ({claimTMT, hash, isPending, isError, error}), [claimTMT, hash, isPending, isError, error])
}