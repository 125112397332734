import React, { useCallback, useEffect, useState } from 'react'
import { CContainer } from '@coreui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoaderIcon, toast } from 'react-hot-toast';
import { shortAddress } from '../utils';

import { format } from 'date-fns';
import { nftContract, tokenID, minWithdraw } from '../config';
import { ethers } from 'ethers';
import { useNFTTokendId } from '../hooks/useNFTTokenId';
import { useUserData } from '../hooks/rkit/useStakeRead';
import { useAccount } from 'wagmi';
import { useApproveNFT, useClaimTMT, useStakeNFT } from '../hooks/rkit/useStakeCall';
import { showError } from '../utils/pageHelpers';
import { useCustomTransaction } from '../hooks/rkit/useTransaction';
import { useTMTAccount } from '../hooks/rkit/useTMTAccount';

export const Balance = () => {
    const copyMsg = () => toast("Copy Successfully !");

    const { address: account } = useTMTAccount()

    const { nftBalance, isApproved, isStaked, depositedAt, generated, dwPrice, lastWithdrawn } = useUserData()

    // const { tokenIds: userTokenId } = useNFTTokendId()

    const [isInTrn, setTransaction] = useState(false);
    const [transactionHashes, setTransactionHashes] = useState({});
    const { approveNFT, hash: approveNFTHash, isPending: isApprovePending } = useApproveNFT()
    const { waitForTransaction, isWaiting } = useCustomTransaction()

    useEffect(() => {
        if (approveNFTHash && !transactionHashes[approveNFTHash]) {
            setTransactionHashes(prev => ({ ...prev, [approveNFTHash]: true }));
            waitForTransaction(approveNFTHash, 'Approving NFT...', 'Approving NFT failed', 'NFT approved successfully')
        }
    }, [approveNFTHash, waitForTransaction, transactionHashes])

    const { stakeNFT, hash: stakeNFTHash, isPending: isStakePending } = useStakeNFT()

    useEffect(() => {
        if (stakeNFTHash && !transactionHashes[stakeNFTHash]) {
            setTransactionHashes(prev => ({ ...prev, [stakeNFTHash]: true }));
            waitForTransaction(stakeNFTHash, 'Staking NFT...', 'Staking NFT failed', 'NFT staked successfully')
        }
    }, [stakeNFTHash, waitForTransaction, transactionHashes])

    const { claimTMT, hash: claimTMTHash, isPending: isClaimPending } = useClaimTMT()

    useEffect(() => {
        if (claimTMTHash && !transactionHashes[claimTMTHash]) {
            setTransactionHashes(prev => ({ ...prev, [claimTMTHash]: true }));
            waitForTransaction(claimTMTHash, 'Claiming TMT...', 'Claiming TMT failed', 'TMT claimed successfully')
        }
    }, [claimTMTHash, waitForTransaction, transactionHashes])


    const [stakedOn, setStakedOn] = useState("");
    const [lastWithdrawAt, setLastWithdrawAt] = useState("");
    const minAmountToClaim = minWithdraw * dwPrice
    useEffect(() => {
        const dt = new Date(Math.round(depositedAt * 1000))
        setStakedOn(format(dt, "dd MMM hh:mm a"))
    }, [depositedAt, setStakedOn])

    useEffect(() => {
        const dt = new Date(Math.round(lastWithdrawn * 1000))
        setLastWithdrawAt(format(dt, "dd MMM hh:mm a"))
    }, [lastWithdrawn, setLastWithdrawAt])

    const claimBtn = useCallback(async () => {
        setTransaction(true)
        try {
            await claimTMT()
        } catch (error) {
            console.error("Error claiming:", error);
            showError(error.message || "Failed to claim");
        } finally {
            setTransaction(false)
        }
    }, [claimTMT, setTransaction, transactionHashes, waitForTransaction])

    const approveBtn = useCallback(
        async () => {
            setTransaction(true)
            try {
                await approveNFT()
            } catch (error) {
                console.error("Error approving NFT:", error);
                showError(error.message || "Failed to approve NFT");
            } finally {
                setTransaction(false)
            }
        },
        [approveNFT, setTransaction],
    )

    const stakeBtn = useCallback(
        async () => {
            if (nftBalance === 0) {
                toast.error("No NFT Found!")
                return;
            }
            setTransaction(true)
            try {
                const result = await stakeNFT(tokenID)
                if (result?.hash && !transactionHashes[result.hash]) {
                    setTransactionHashes(prev => ({ ...prev, [result.hash]: true }));
                    waitForTransaction(result.hash, 'Staking NFT...', 'Staking NFT failed', 'NFT staked successfully')
                }
            } catch (error) {
                console.error("Error staking NFT:", error);
                showError(error.message || "Failed to stake NFT");
            } finally {
                setTransaction(false)
            }
        },
        [stakeNFT, setTransaction, nftBalance, transactionHashes, waitForTransaction],
    )

    return (
        <CContainer id='stakeDiv'>
            <div className='balanceViewBx mb-5 mb-md-0'>
                <div className="bgbx"></div>
                <ul>
                    <li>Your Balance <span>{nftBalance}</span></li>
                    <li>Token ID <span>{tokenID}
                        <CopyToClipboard text={tokenID} onCopy={copyMsg}>
                            <svg fill="#ffffff" height="30" width="30" version="1.1" viewBox="0 0 330 330"><g><path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15  c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95  c-8.284,0-15,6.716-15,15v165H50V30z"></path><path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"></path> <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"></path><path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z"></path></g></svg></CopyToClipboard></span>
                    </li>
                    <li>NFT <span>{shortAddress(nftContract)}
                        <CopyToClipboard text={nftContract} onCopy={copyMsg}><svg fill="#ffffff" height="30" width="30" version="1.1" viewBox="0 0 330 330"><g><path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15  c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95  c-8.284,0-15,6.716-15,15v165H50V30z"></path><path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"></path> <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"></path><path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z"></path></g></svg></CopyToClipboard></span>
                    </li>
                    <li>Price <span>1 TMT = {dwPrice.toFixed(2)} USDT</span></li>
                    {
                        isStaked && <li>Min. Claim <span>{minAmountToClaim.toFixed(2)} TMT</span></li>
                    }
                    {
                        isStaked && <li>Staked On <span>{stakedOn}</span></li>
                    }
                    {
                        isStaked && parseFloat(generated ? ethers.utils.formatEther(generated) : "0") >= 0 && <li>Generated TMT <span>{parseFloat(generated ? parseFloat(ethers.utils.formatEther(generated)).toPrecision(4) : "0")} TMT</span></li>
                    }
                    {
                        isStaked && lastWithdrawAt && (lastWithdrawAt !== stakedOn) && <li>Last Withdrawn <span>{lastWithdrawAt}</span></li>
                    }
                </ul>
                {account && <div className='nftBtnMain' style={{ marginTop: "16px", textAlign: "center" }}>
                    {
                        (isApproved && !isStaked && nftBalance >= 0)
                        &&
                        <div className="buttonGroup bannerbtn2 d-flex align-items-center mt-4">
                            <button className="kave-btn tech_btn" disabled={isInTrn || isWaiting} href="javascript:;" onClick={stakeBtn}>
                                <i></i><i></i>
                                <span> {(isStakePending || isWaiting) && <LoaderIcon />}
                                    {!isStakePending && !isWaiting && "Stake"}</span>
                            </button>
                        </div>
                    }
                    {
                        !isApproved &&
                        <div className="buttonGroup bannerbtn2 d-flex align-items-center mt-4">
                            <button className="kave-btn tech_btn" disabled={isApprovePending || isWaiting} href="javascript:;" onClick={approveBtn}>
                                <i></i><i></i>
                                <span> {
                                    !isApprovePending && !isWaiting && "Approve NFT"
                                }
                                    {
                                        (isApprovePending || isWaiting) && <LoaderIcon />
                                    }</span>
                            </button>
                        </div>
                    }
                    {
                        isStaked && parseFloat(generated ? ethers.utils.formatEther(generated) : "0") >= minAmountToClaim &&
                        <div className="buttonGroup bannerbtn2 d-flex align-items-center mt-4">
                            <button className="kave-btn tech_btn" disabled={parseFloat(generated ? ethers.utils.formatEther(generated) : "0") < minAmountToClaim || isClaimPending || isWaiting} href="javascript:;" onClick={claimBtn}>
                                <i></i><i></i>
                                <span className='kave-line' style={{ display: "flex", justifyContent: "center" }}>{
                                    (isClaimPending || isWaiting) && <LoaderIcon />
                                }
                                    {
                                        !isClaimPending && !isWaiting && "Claim TMT"
                                    }</span>
                            </button>
                        </div>
                    }
                </div>}
            </div>
        </CContainer>
    )
}