import { useAccount, useReadContracts } from "wagmi"
import { stakeContractConfig } from "../../config/contract"
import { useContractCall } from "./useContractCall"
import { nftContract, stakeContract, tokenID } from "../../config"
import sftABI from '../../config/abis/IERC1155.json'
import { useEffect, useMemo, useState } from "react"
import { formatEther } from "ethers/lib/utils"
import { useTMTAccount } from "./useTMTAccount"
import { ethers } from "ethers"

export const useStakeRead = (method, args) => {
    const result = useContractCall({
        ...stakeContractConfig,
        method: method,
        args: args || []
    });

    const { data, isLoading, isPending, isError, error } = result;

    return useMemo(() => ({ data, isLoading, isPending, isError, error }), [data, isLoading, isPending, isError, error]);
}

export const useUserData = () => {
    const { address } = useTMTAccount()

    const { data: nftBalance } = useContractCall({
        address: nftContract,
        abi: sftABI,
        method: "balanceOf",
        args: [address, tokenID]
    })

    const { data: isNFTApproved } = useContractCall({
        address: nftContract,
        abi: sftABI,
        method: "isApprovedForAll",
        args: [address, stakeContract]
    })

    const { data: userDatas } = useStakeRead("userDatas", [address]);
    const { data: generated } = useStakeRead("getGenerated", [address]);
    const { data: perNft } = useStakeRead("getTotalReturnInTMTC", [address]);
    const { data: totalReturnInUsdt } = useStakeRead("getTotalReturn", [address]);
    const { data: dwPrice } = useStakeRead("getTMTPrice", []);
    const { data: totalDays } = useStakeRead("totalDays", []);

    return useMemo(() => {
        console.debug(isNFTApproved, nftBalance, userDatas, generated, perNft, dwPrice, totalDays)
        if (!userDatas) return {
            isApproved: false,
            nftBalance: '0',
            depositedAt: '0',
            generated: '0',
            lastWithdrawn: '0',
            returned: '0',
            returnedUSDT: '0',
            nftPriceAtStaked: '0',
            isStaked: false,
            maxReturn: '0',
            dwPrice: 0,
            totalDays: 0
        }
        return {
            isApproved: isNFTApproved ?? false,
            nftBalance: nftBalance?.toString() ?? '0',
            depositedAt: userDatas[4]?.toString() ?? '0',
            generated: generated?.toString() ?? '0',
            lastWithdrawn: userDatas[3]?.toString() ?? '0',
            returned: userDatas[2]?.toString() ?? '0',
            returnedUSDT: userDatas[6]?.toString() ?? '0',
            totalReturnInUsdt: ethers.utils.formatEther(totalReturnInUsdt?.toString()) ?? '0',
            nftPriceAtStaked: ethers.utils.formatEther(userDatas[5]?.toString()) ?? '0',
            isStaked: userDatas[1] !== ethers.constants.AddressZero,
            maxReturn: ethers.utils.formatEther(perNft?.toString()) ?? '0',
            dwPrice: dwPrice ? parseFloat(formatEther(dwPrice)) : 0,
            totalDays: totalDays ? Number(totalDays.toString()) : 0
        };
    }, [address, nftBalance, isNFTApproved, userDatas, generated, perNft, dwPrice, totalDays, totalReturnInUsdt]);
}